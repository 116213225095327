/**
 * Element parameters in dataset (attribute)
 * data-clipboard-text - Values to be stored in clipboard
 * data-error - Text to show if error
 * data-success - Text to show if success
 * data-button-text - Optional. Text to be shown after copied time out.
 * data-trigger-target - Optional. Child element CSS selector that will trigger the copy.
 * data-status-container - Optional. Child element CSS selector that will show the copy status
 */
class Clipboard {
  constructor() {
    this.clipboards = document.querySelectorAll(".js-clipboard");
    this.bind();
  }

  toggleStatus(clipboard, status) {
    const color = status + "Color";

    let statusContainer = clipboard;
    if (clipboard.dataset.statusContainer) {
      statusContainer = clipboard.querySelector(clipboard.dataset.statusContainer);
    }
    const content = statusContainer.innerHTML;
    statusContainer.innerText = clipboard.dataset[status];
    clipboard.classList.add(clipboard.dataset[color]);

    setTimeout(() => {
      if (clipboard.dataset.buttonText) {
        statusContainer.innerText = clipboard.dataset.buttonText;
      } else {
        statusContainer.innerHTML = content;
      }
      clipboard.classList.remove(clipboard.dataset[color]);
    }, 3000);
  }

  copy(element) {
    try {
      navigator.clipboard.writeText(element.dataset.clipboardText);
      return "success";
    } catch {
      return "error";
    }
  }

  bind() {
    if (!this.clipboards) {
      return;
    }

    this.clipboards.forEach((clipboard) => {
      let clickElem = clipboard;
      if (clipboard.dataset.triggerTarget) {
        clickElem = clipboard.querySelector(clipboard.dataset.triggerTarget);
      }
      clickElem.addEventListener("click", (e) => {
        const elem = e.target.closest('.js-clipboard');
        this.toggleStatus(elem, this.copy(elem));
      })
    });
  }
}

export default Clipboard;
